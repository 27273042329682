import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LoadingIndicator from "../components/LoadingIndicator"
import logo from "../images/logo-transparent-white.png"

import "../components/colors.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>
      <img
        src={logo}
        width={250}
        alt="Phirtual logo"
        style={{ marginBottom: 0 }}
      />
    </h1>
    <p
      style={{
        fontSize: "1.25rem",
      }}
    >
      We're charging up for our public launch. Check out our <a href="https://blog.phirtual.io/" target="_blank">blog</a> while you wait!
    </p>
    <LoadingIndicator />
  </Layout>
)

export default IndexPage
